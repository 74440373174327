import {
  Ability,
  ForcedSubject,
  AbilityBuilder,
  AbilityClass,
} from "@casl/ability";
import { UserRoles } from "@/@types/User";

export enum AbilityActions {
  MANAGE = "manage",
}

export enum AbilitySubjects {
  TASK = "task",
  TEAM = "team",
  TEMPLATE = "template",
  USER = "user",
  ALL = "all",
}

export type AppAbility = Ability<
  [
    AbilityActions,
    AbilitySubjects | ForcedSubject<Exclude<AbilitySubjects, "all">>,
  ]
>;

type DefinePermissions = ({
  can,
  cannot,
}: Pick<AbilityBuilder<AppAbility>, "can" | "cannot">) => void;

class AbilityService {
  #ability: AppAbility;
  #builder: AbilityBuilder<AppAbility>;
  #permissions: Record<UserRoles | "defaultRole", DefinePermissions> = {
    defaultRole({ cannot }) {
      cannot(AbilityActions.MANAGE, AbilitySubjects.ALL);
    },
    [UserRoles.USER]({ can, cannot }) {
      // user can manage tasks & templates
      // which belong to this user
      // access is being granted only to their own instances
      // this is controlled by the backend
      can(AbilityActions.MANAGE, AbilitySubjects.TASK);
      can(AbilityActions.MANAGE, AbilitySubjects.TEMPLATE);
      cannot(AbilityActions.MANAGE, AbilitySubjects.USER);
    },
    [UserRoles.ADMIN]({ can }) {
      // admin can manage everything
      can(AbilityActions.MANAGE, AbilitySubjects.ALL);
    },
  };

  constructor() {
    this.#builder = new AbilityBuilder<AppAbility>(
      Ability as AbilityClass<AppAbility>,
    );

    this.#ability = this.#builder.build();
  }

  get abilities() {
    return this.#ability;
  }

  updateAbilities(role: UserRoles | null = null) {
    const { can, cannot, rules } = this.#builder;

    if (typeof this.#permissions[role || "defaultRole"] === "function") {
      this.#permissions[role || "defaultRole"]({ can, cannot });
    } else {
      throw new Error(`Trying to use unknown role "${role}"`);
    }

    this.#ability.update(rules);
  }
}

export default new AbilityService();
