import HttpClient from "@/api/Api";
import { User } from "@/@types/User";

type LogInPayload = {
  nickname: string;
  password: string;
  rememberMe: boolean;
};

type LogInResponse = {
  accessToken: string;
  refreshToken: string;
  user: Pick<User, "id" | "nickname" | "role">;
};

type ChangePasswordPayload = {
  oldPassword: LogInPayload["password"];
  newPassword: LogInPayload["password"];
};

class AuthApi extends HttpClient {
  constructor() {
    super();
    this.setHeader("Content-Type", "application/json");
  }

  logIn(payload: LogInPayload) {
    return this.post<LogInPayload, LogInResponse>("/auth/login", payload);
  }

  refreshToken() {
    return this.post<{ token: string }, LogInResponse>("/auth/refresh-token", {
      token: window.localStorage.getItem("refreshToken") || "",
    });
  }

  logoutOfAllDevices() {
    return this.get("/auth/logout-all-device");
  }

  getSession() {
    return this.get<LogInResponse["user"]>("/auth/me", {
      headers: {},
    });
  }

  changePassword(payload: ChangePasswordPayload) {
    return this.post<ChangePasswordPayload>("/auth/change_password", payload);
  }
}

export { LogInPayload, LogInResponse, ChangePasswordPayload };
export default AuthApi;
