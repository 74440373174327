import { RouteRecordRaw } from "vue-router";
import { FormStates } from "@/@types/FormStates";

export const template: RouteRecordRaw = {
  path: "templates",
  name: "Templates",
  component: () => import("@/views/Nested.vue"),
  children: [
    {
      path: "",
      name: "TemplatesList",
      // route level code-splitting
      // this generates a separate chunk (list.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "templates.list" */ "@/views/templates/List.view.vue"
        ),
    },
    {
      path: "create",
      name: "TemplatesCreate",
      props: { formState: FormStates.create },
      // route level code-splitting
      // this generates a separate chunk (form.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "templates.form" */ "@/views/templates/Form.view.vue"
        ),
    },
    {
      path: ":id",
      name: "Template",
      // route level code-splitting
      // this generates a separate chunk (show.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("@/views/Nested.vue"),
      children: [
        {
          path: "edit",
          name: "TemplateUpdate",
          props: { formState: FormStates.update },
          component: () =>
            import(
              /* webpackChunkName: "templates.form" */ "@/views/templates/Form.view.vue"
            ),
        },
      ],
    },
  ],
};
