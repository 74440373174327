import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import forLoggedIn from "@/router/middleware/forLoggedIn";
import notForLoggedIn from "@/router/middleware/notForLoggedIn";
import Auth from "@/services/auth.service";
import { task } from "@/router/task.route";
import { template } from "@/router/template.route";
import { team } from "@/router/team.route";
import { user } from "@/router/user.route";
import { ContentLibrary } from "@/router/content-list.route";
import { admin } from "@/router/admin.route";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "LogIn",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/LogIn.view.vue"),
    beforeEnter: [notForLoggedIn],
  },

  {
    path: "/",
    name: "Root",
    redirect: () => (Auth.isLoggedIn ? "/tasks" : "/login"),
    beforeEnter: [forLoggedIn],
    component: () => import("@/views/Nested.vue"),
    children: [task, template, team, user, ContentLibrary, admin],
  },

  {
    path: "/404",
    name: "NotFound",
    meta: {
      middleware: [forLoggedIn],
    },
    component: () =>
      import(/* webpackChunkName: "404" */ "@/views/404.view.vue"),
  },

  // default
  {
    path: "/:pathMatch(.*)*",
    redirect: {
      name: "NotFound",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
/* */
