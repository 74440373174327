enum FormStates {
  create,
  clone,
  update,
  restore, // for failed tasks
  createFromFailed, // for partially failed tasks
}

type TaskFormStates = Exclude<FormStates, FormStates.update>;
type TemplateFormStates = Exclude<
  FormStates,
  FormStates.restore | FormStates.createFromFailed | FormStates.clone
>;

export { FormStates, TaskFormStates, TemplateFormStates };
