import AuthApi, { LogInPayload } from "@/api/Auth";
import tokenKey from "@/config/tokenKey";
import fetchInterceptor from "fetch-intercept";
import { UserRoles } from "@/@types/User";
import abilityService from "@/services/ability.service";
import { jwtDecode } from "jwt-decode";

const refreshTokenKey = "refreshToken";

type DispatchTypes = "login" | "logout";

class AuthService {
  #api: AuthApi = new AuthApi();
  #id = 0;
  #userRole: UserRoles | null = null;
  refreshTimer?: number;

  async logIn(cred: LogInPayload) {
    const { accessToken, refreshToken, user } = await this.#api.logIn(cred);
    this.setNewToken(accessToken, refreshToken);
    this.setRole(user.role as UserRoles);
    this.setId(user.id);
    this.dispatch("login");
  }

  async getSession() {
    const { role, id } = await this.#api.getSession();
    this.setRole(role as UserRoles);
    this.setId(id);
  }

  async refreshToken() {
    const { accessToken, refreshToken } = await this.#api.refreshToken();
    this.setNewToken(accessToken, refreshToken);
  }

  logOut() {
    window.localStorage.removeItem(tokenKey);
    window.localStorage.removeItem(refreshTokenKey);
    if (this.refreshTimer) clearTimeout(this.refreshTimer);
    this.setRole(null);
    this.dispatch("logout");
  }

  async logoutOfAllDevices() {
    await this.#api.logoutOfAllDevices();
    this.logOut();
  }

  setNewToken(accessToken: string, refreshToken: string) {
    window.localStorage.setItem(tokenKey, accessToken);
    window.localStorage.setItem(refreshTokenKey, refreshToken);
    this.setRefreshTokenTimer(accessToken);
  }

  setRole(role: UserRoles | null) {
    this.#userRole = role;
    abilityService.updateAbilities(role);
  }

  setId(id: number) {
    this.#id = id;
  }

  get id() {
    return this.#id;
  }

  get role() {
    return this.#userRole;
  }

  get token() {
    return window.localStorage.getItem(tokenKey);
  }

  get isLoggedIn() {
    return !!this.token;
  }

  get authHeader() {
    return this.token ? { "x-access-token": this.token } : {};
  }

  constructor() {
    if (this.token) this.setRefreshTokenTimer(this.token);
  }

  setRefreshTokenTimer(accessToken: string) {
    if (this.refreshTimer) clearTimeout(this.refreshTimer);
    const { exp } = jwtDecode(accessToken) as { exp: number };
    const preRefreshTime = 5000;

    if (exp) {
      const calculatedTime = exp * 1000 - preRefreshTime - Date.now();
      if (calculatedTime < 0) this.refreshToken();
      else
        this.refreshTimer = setTimeout(
          () => this.refreshToken(),
          calculatedTime,
        );
    }
  }

  watch(what: DispatchTypes, callback: () => void) {
    document.addEventListener(what, callback);
  }

  unwatch(what: DispatchTypes, listener: () => void) {
    document.removeEventListener(what, listener);
  }

  dispatch(what: DispatchTypes) {
    document.dispatchEvent(new CustomEvent(what));
  }
}

const Auth = new AuthService();

fetchInterceptor.register({
  request(url, config) {
    config.headers = {
      ...config.headers,
      ...Auth.authHeader,
    };
    return [url, config];
  },

  response(response) {
    if (!response.ok && response.status === 401) Auth.logOut();

    // const newToken = response.headers.get("token");
    // if (newToken) Auth.setNewToken(newToken);
    return response;
  },
});

export default Auth;
