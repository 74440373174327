import TaskStatus from "@/@types/TaskStatus";

enum TeamRole {
  TEAM_ADMIN = "team_admin",
  MEMBER = "member",
}

enum UserRoles {
  USER = "user",
  ADMIN = "admin",
}

type TaskCountersByStatus = {
  [key in TaskStatus]: number;
};

type User = {
  id: number;
  membershipId: number;
  nickname: string;
  // for User in list role is enum,
  // but when we create a new User we define role as id
  role: UserRoles | string;
  team: string | null;
  teamRole: TeamRole | null;
  lastLoginAt: string;
  counters: TaskCountersByStatus;
  membership?: {
    id: number;
    teamRole: string;
    team: {
      id: number;
      title: string;
    };
  };
};

export { User, UserRoles, TeamRole };
