class HttpException extends Error {
  constraints: Record<string, string>;
  status: number;
  message: string;

  constructor(
    status: Response["status"],
    message: Error["message"],
    constraints = {},
  ) {
    super(message);
    this.status = status || 500;
    this.message = message || "Unexpected error occurred";
    this.constraints = constraints;
  }
}

export default HttpException;
