import ValueOf from "@/@types/ValueOf";
import UnauthorizedException from "@/exceptions/UnauthorizedException";
import NotFoundException from "@/exceptions/NotFoundException";
import BadRequestException from "@/exceptions/BadRequestException";
import ServerFailedException from "@/exceptions/ServerFailedException";
import HttpException from "@/exceptions/HttpException";
import ConflictException from "@/exceptions/ConflictException";
import NotAcceptableException from "@/exceptions/NotAcceptableException";

type HttpClientOptions = {
  baseURL: string;
  headers: { [key: string]: string };
};

type FetchJSONOptions = Partial<RequestInit> & {
  asJson?: boolean;
  asBlob?: boolean;
};

class HttpClient {
  #baseURL: HttpClientOptions["baseURL"];
  #headers: HttpClientOptions["headers"];

  constructor(options: Partial<HttpClientOptions> = {}) {
    this.#baseURL = window.settings.API_URL;
    this.#headers = options.headers || {};
  }

  async #fetchJSON<T>(
    endpoint: string,
    options: FetchJSONOptions = {},
  ): Promise<(Response & { body: T }) | void> {
    const res = await fetch(this.#baseURL + endpoint, {
      ...options,
      headers: this.#headers,
    });

    if (!res.ok) {
      const body = await res.json();

      if (res.status == 406) {
        throw new NotAcceptableException(body.message);
      }

      switch (body.status) {
        case 400:
          throw new BadRequestException(body.message, body?.constraints || {});
        case 401:
          throw new UnauthorizedException(body.message);
        case 404:
          throw new NotFoundException(body.message);
        case 406:
          throw new NotAcceptableException(body.message);
        case 409:
          throw new ConflictException(body.message, body?.constraints || {});
        case 500:
          throw new ServerFailedException(body.message);
        default:
          throw new HttpException(body.status, body.message);
      }
    }

    if (res.status === 204) return;
    return res as Response & { body: T };
  }

  #setRequestBody<T>(body: T) {
    return body instanceof FormData
      ? body
      : body
        ? JSON.stringify(body)
        : undefined;
  }

  setHeader(
    key: keyof HttpClientOptions["headers"],
    value: ValueOf<HttpClientOptions["headers"]>,
  ) {
    this.#headers[key] = value;
    return this;
  }

  getHeader(key: keyof HttpClientOptions["headers"]) {
    return this.#headers[key];
  }

  setBearerAuth(token: string) {
    this.#headers.auth = token;
    return this;
  }

  get<R = any>(endpoint: string, options: FetchJSONOptions = {}) {
    return this.#fetchJSON<R>(endpoint, {
      ...options,
      method: "GET",
    }).then(
      (res) => (options.asBlob ? res?.blob() : res?.json()) as Promise<R>,
    );
  }

  post<B = any, R = any>(
    endpoint: string,
    body: B,
    options: FetchJSONOptions = {},
  ) {
    return this.#fetchJSON<R>(endpoint, {
      ...options,
      body: this.#setRequestBody<B>(body),
      method: "POST",
    }).then((res) => res?.json() as Promise<R>);
  }

  put<B = any, R = any>(
    endpoint: string,
    body: B,
    options: FetchJSONOptions = {},
  ) {
    return this.#fetchJSON<R>(endpoint, {
      ...options,
      body: this.#setRequestBody<B>(body),
      method: "PUT",
    }).then((res) => res?.json() as Promise<R>);
  }

  patch<B = any, R = any>(
    endpoint: string,
    body: B,
    options: FetchJSONOptions = {},
  ) {
    return this.#fetchJSON<R>(endpoint, {
      ...options,
      body: this.#setRequestBody<B>(body),
      method: "PATCH",
    }).then((res) => res?.json() as Promise<R>);
  }

  delete<R = any>(endpoint: string, options: FetchJSONOptions = {}) {
    return this.#fetchJSON<R>(endpoint, {
      ...options,
      method: "DELETE",
    });
  }
}

export default HttpClient;
