import { NavigationGuard } from "vue-router";
import Auth from "@/services/auth.service";

const notForLoggedIn: NavigationGuard = (to, from, next) => {
  if (Auth.isLoggedIn) {
    next("/tasks");
    return false;
  }
  next();
};

export default notForLoggedIn;
