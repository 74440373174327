import HttpClient from "@/api/Api";
import {
  Team,
  TeamInfoResponse,
  AllTeamInfoResponse,
  TeamHistoryResponse,
} from "@/@types/Team";
import { TeamCreatePayload } from "@/@types/TeamCreate.api";
import { FetchListOptions } from "@/@types/UserList.api";
import PaginatorListParams from "@/@types/PaginatorListParams";
import { TeamCreateMemberPayload } from "@/@types/TeamMemberCreate.api";
import { User } from "@/@types/User";

const BASE_API = "/teams";

class Teams extends HttpClient {
  constructor() {
    super();
    this.setHeader("Content-Type", "application/json");
  }

  create(team: TeamCreatePayload) {
    return this.post<TeamCreatePayload, void>(BASE_API, team);
  }

  createMember(id: Team["id"], member: TeamCreateMemberPayload): Promise<User> {
    return this.post<TeamCreateMemberPayload, User>(
      `${BASE_API}/${id}/members`,
      member,
    );
  }

  remove(id: Team["id"]) {
    return this.delete<number>(`${BASE_API}/${id}`, {
      headers: { Accept: "application/json" },
    });
  }

  update(id: Team["id"], team: TeamCreatePayload) {
    return this.patch<TeamCreatePayload, void>(`${BASE_API}/${id}`, team);
  }

  fetchList(options: FetchListOptions & PaginatorListParams = {}) {
    const params = new URLSearchParams();
    params.append("count", String(options.count || 100));
    params.append("page", String(options.page || 1));

    return this.get<{ total: number; items: Team[] }>(
      `${BASE_API}?${params.toString()}`,
    );
  }

  getById(id: number): Promise<Team> {
    return this.get(`${BASE_API}/${id}`, {
      headers: { Accept: "application/json" },
    });
  }

  getTeamInfo() {
    return this.get<TeamInfoResponse>(`${BASE_API}/pricing-settings`, {
      headers: { Accept: "application/json" },
    });
  }

  getAllTeamInfo(options: PaginatorListParams = {}) {
    const params = new URLSearchParams();
    params.append("count", String(options.count || 100));
    params.append("page", String(options.page || 1));

    return this.get<{ items: AllTeamInfoResponse[]; total: number }>(
      `${BASE_API}/global/pricing-settings/all?${params.toString()}`,
      {
        headers: { Accept: "application/json" },
      },
    );
  }

  getTeamHistory(
    teamId: number,
    options: { beforeId?: number; limit?: number } = {},
  ) {
    const params = new URLSearchParams();
    if (options.beforeId) {
      params.append("beforeId", String(options.beforeId));
    }
    params.append("limit", String(options.limit || 1));

    return this.get<TeamHistoryResponse>(
      `${BASE_API}/${teamId}/pricing/history?${params.toString()}`,
      {
        headers: { Accept: "application/json" },
      },
    );
  }

  getDefaultTeamSettings() {
    return this.get<{
      taskPriceCents: number;
      maxReactionCount: number;
      maxReplyCount: number;
      maxReviewCount: number;
      updatedAt: "2024-06-24T12:35:01.130Z";
    }>(`${BASE_API}/global/pricing-settings/default`, {
      headers: { Accept: "application/json" },
    });
  }

  setDefaultTeamSettings(data: {
    taskPriceCents: number;
    maxReactionCount: number;
    maxReplyCount: number;
    maxReviewCount: number;
  }) {
    return this.patch<
      {
        taskPriceCents: number;
        maxReactionCount: number;
        maxReplyCount: number;
        maxReviewCount: number;
      },
      {
        taskPriceCents: number;
        maxReactionCount: number;
        maxReplyCount: number;
        maxReviewCount: number;
        updatedAt: string;
      }
    >(`${BASE_API}/global/pricing-settings/default`, data);
  }

  adjustBalance(
    id: number,
    body: {
      balanceChangeCents: number;
      note: string | undefined;
    },
  ) {
    return this.patch<
      {
        balanceChangeCents: number;
        note: string | undefined;
      },
      {
        balanceCents: number;
      }
    >(`${BASE_API}/${id}/balance/adjust`, body);
  }

  setTeamInfo(
    id: number,
    body: {
      taskPriceCents: number | null;
      maxReactionCount: number | null;
      maxReplyCount: number | null;
      isOverspendAllowed: boolean;
      overspendBalanceLimitCents: number;
    },
  ) {
    return this.patch<
      {
        taskPriceCents: number | null;
        maxReactionCount: number | null;
        maxReplyCount: number | null;
        isOverspendAllowed: boolean;
        overspendBalanceLimitCents: number;
      },
      AllTeamInfoResponse
    >(`${BASE_API}/${id}/pricing-settings`, body);
  }

  fetchOptionsList() {
    return this.get<{
      items: {
        id: number;
        title: string;
      }[];
    }>(`${BASE_API}/titles`);
  }
}

export default new Teams();
