import { RouteRecordRaw } from "vue-router";
import canManageUsers from "@/router/middleware/canManageUsers";

export const user: RouteRecordRaw = {
  path: "users",
  name: "Users",
  component: () => import("@/views/Nested.vue"),
  beforeEnter: [canManageUsers],
  children: [
    {
      path: "",
      name: "UsersList",
      component: () =>
        import(
          /* webpackChunkName: "users.list" */ "@/views/users/List.view.vue"
        ),
    },
    {
      path: "create",
      name: "UserCreate",
      component: () =>
        import(
          /* webpackChunkName: "users.form" */ "@/views/users/Form.view.vue"
        ),
    },
    {
      path: ":id",
      name: "User",
      component: () => import("@/views/Nested.vue"),
      children: [
        {
          path: "edit",
          name: "UserEdit",
          component: () =>
            import(
              /* webpackChunkName: "users.form" */ "@/views/users/EditUser.view.vue"
            ),
        },
      ],
    },
  ],
};
