import { defineStore } from "pinia";
import { TeamInfoResponse } from "@/@types/Team";
import Teams from "@/api/Teams";

const useUserStore = defineStore("user", {
  state: () =>
    ({
      team: null,
    }) as {
      team: TeamInfoResponse | null;
    },
  actions: {
    async getTeamInfo() {
      try {
        const result = await Teams.getTeamInfo();
        this.team = result;
      } catch (e) {
        this.team = null;
      }
    },
  },
});

export { useUserStore };
