import { RouteRecordRaw } from "vue-router";
import { FormStates } from "@/@types/FormStates";

export const task: RouteRecordRaw = {
  path: "tasks",
  name: "Tasks",
  component: () => import("@/views/Nested.vue"),
  children: [
    {
      path: "",
      name: "TasksList",
      // route level code-splitting
      // this generates a separate chunk (list.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "tasks.list" */ "@/views/tasks/List.view.vue"
        ),
    },
    {
      path: "create",
      name: "TaskCreate",
      props: { formState: FormStates.create },
      // route level code-splitting
      // this generates a separate chunk (form.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "tasks.form" */ "@/views/tasks/Form.view.vue"
        ),
    },
    {
      path: ":id",
      name: "Task",
      component: () =>
        import(
          /* webpackChunkName: "tasks.current" */ "@/views/tasks/Show.view.vue"
        ),
    },
    {
      path: ":id/clone",
      name: "TaskClone",
      props: { formState: FormStates.clone },
      component: () =>
        import(
          /* webpackChunkName: "tasks.form" */ "@/views/tasks/Form.view.vue"
        ),
    },
  ],
};
