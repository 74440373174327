import { NavigationGuard } from "vue-router";
import abilityService, {
  AbilityActions,
  AbilitySubjects,
} from "@/services/ability.service";

const canManageUsers: NavigationGuard = (to, from, next) => {
  if (
    !abilityService.abilities.can(AbilityActions.MANAGE, AbilitySubjects.USER)
  ) {
    next("/404");
    return false;
  }
  next();
};

export default canManageUsers;
