import { NavigationGuard } from "vue-router";
import Auth from "@/services/auth.service";

const forLoggedIn: NavigationGuard = (to, from, next) => {
  if (!Auth.isLoggedIn) {
    next("/login");
    return false;
  }
  next();
};

export default forLoggedIn;
