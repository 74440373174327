import { RouteRecordRaw } from "vue-router";
import { FormStates } from "@/@types/FormStates";

export const team: RouteRecordRaw = {
  path: "teams",
  name: "Teams",
  component: () => import("@/views/Nested.vue"),
  children: [
    {
      path: "",
      name: "TeamsList",
      component: () =>
        import(
          /* webpackChunkName: "teams.list" */ "@/views/teams/List.view.vue"
        ),
    },
    {
      path: "create",
      name: "TeamCreate",
      component: () =>
        import(
          /* webpackChunkName: "teams.form" */ "@/views/teams/Form.view.vue"
        ),
    },
    {
      path: ":id",
      name: "Team",
      component: () => import("@/views/Nested.vue"),
      children: [
        {
          path: "edit",
          name: "TeamUpdate",
          props: { formState: FormStates.update },
          component: () =>
            import(
              /* webpackChunkName: "teams.form" */ "@/views/teams/Form.view.vue"
            ),
        },
      ],
    },
  ],
};
