import { RouteRecordRaw } from "vue-router";
import { FormStates } from "@/@types/FormStates";

export const ContentLibrary: RouteRecordRaw = {
  path: "content-library",
  name: "ContentLibrary",
  component: () => import("@/views/Nested.vue"),
  children: [
    {
      path: "",
      name: "ContentLibraryList",
      // route level code-splitting
      // this generates a separate chunk (list.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "templates.list" */ "@/views/content-library/List.view.vue"
        ),
    },
    {
      path: "create",
      name: "ContentLibraryCreate",
      props: { formState: FormStates.create },
      // route level code-splitting
      // this generates a separate chunk (form.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "templates.form" */ "@/views/content-library/Form.view.vue"
        ),
    },
    {
      path: ":id",
      name: "ContentLibraryEdit",
      // route level code-splitting
      // this generates a separate chunk (show.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("@/views/Nested.vue"),
      children: [
        {
          path: "",
          name: "TaskShow",
          component: () =>
            import(
              /* webpackChunkName: "tasks.show" */ "@/views/tasks/Show.view.vue"
            ),
        },
        {
          path: "edit",
          name: "ContentLibraryUpdate",
          props: { formState: FormStates.update },
          component: () =>
            import(
              /* webpackChunkName: "templates.form" */ "@/views/content-library/Form.view.vue"
            ),
        },
      ],
    },
  ],
};
