<template>
  <div class="container p-4">
    <Header v-if="isLoggedIn" />
    <RouterView />
  </div>
</template>

<script setup lang="ts">
import Header from "@/components/layout/LayoutHeader.vue";
import Auth from "@/services/auth.service";
import { provide, ref } from "vue";
import { useRouter } from "vue-router";
import { useAppAbility } from "@/use/appAbilities";
import { AbilityActions, AbilitySubjects } from "@/services/ability.service";

const isLoggedIn = ref(Auth.isLoggedIn);
const router = useRouter();

const abilities = useAppAbility();
const canManageUsers = () =>
  abilities.can(AbilityActions.MANAGE, AbilitySubjects.USER);

Auth.watch("login", () => (isLoggedIn.value = true));
Auth.watch("logout", () => {
  isLoggedIn.value = false;
  router.push({
    name: "LogIn",
    query: {
      next: router.currentRoute.value.fullPath,
    },
  });
});

provide("isLoggedIn", isLoggedIn);
provide("canManageUsers", canManageUsers);
</script>
